@use "sass:math";
@import '../../../../../../config/variables.scss';
@import '../../../../../../config/mixins.scss';

.single-stock-wrapper {
    display: flex;
    flex-wrap: wrap;
    flex: 0 0 100%;
    justify-content: center;
    align-items: center;
    padding: $main-spacing / 2;

    span {
        font-size: 0.9rem;
    }

    input[type='radio'] {
        display: none;

    }

    .svg-wrapper {
        padding: $main-spacing;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .single-stock-option {
        cursor: pointer;
        position: relative;
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;

        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        border: 1px solid $color-disabled;
        padding: $main-spacing;
        text-align: center;
        box-shadow: 0 15px 20px -15px #4d4d4d66;

        @include gt-sm {
            min-height: 100px;
        }
    }
}

.single-stock-option-disable {
    cursor: not-allowed !important;
}

@include gt-xs {
    .single-stock-wrapper {
        flex: 1 1 50%;
    }
}

@include gt-sm {
    .single-stock-option-disable {
        &::before {
            width: 100%;
            height: $single-option-height-big;
        }
    }
}