@use "sass:math";
@import '../../../config/variables';
@import '../../../config/mixins.scss';

.button {
  min-width: 95px;
  padding: $main-spacing $main-spacing;
  white-space: nowrap;
  margin: math.div($main-spacing, 2) 0;
  background: lighten($color-background, 10%);
  color: $color-text;
  border: none;
  display: flex;
  cursor: pointer;
  text-decoration: none;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 300;
  width: 100%;
  font-family: $font-primary;
  border-radius: 4px;

  transition: $transition-time background-color, $transition-time border-color;
  box-shadow: $box-shadow-small rgba($color-text, 0.25);

  &:link {
    color: $color-text;
  }

  &:visited {
    color: $color-text;
  }

  &.auto {
    align-self: flex-start;
  }

  &.rounded {
    border-radius: 26px;
  }

  &.button-primary {
    color: $color-text-button;
  }

  &.button-danger {
    background: $color-danger;
    color: $color-text-button;
    &:hover {
      background: lighten($color-danger, 10%);
    }
  }

  &.button-success {
    background: $color-success;
    color: $color-text-button;
    &:hover {
      background: lighten($color-success, 10%);
    }
  }

  &.button-tertiary {
    background: $color-tertiary;
    text-transform: uppercase;
    color: $color-text-button;
    &:hover {
      background: lighten($color-tertiary, 10%);
    }
  }

  &.button-small {
    padding: math.div($main-spacing, 2) $main-spacing;
    font-size: 0.9rem;
    border-radius: 3px;
  }

  &.button-big {
    font-size: 1.2rem;
    padding: $main-spacing * 1.5;
    font-weight: 500;
    border-radius: 6px;

    @include gt-md {
      font-size: 1.5rem;
    }
  }

  &.button-outline {
    text-transform: none;
    padding: 8px 20px;
    font-weight: 500;

    &:hover {
      box-shadow: inset 0 -1px 1px -1px $color-primary;
    }

    &.disabled,
    &:disabled {
      background: #ccc;
      border: 2px solid #aaa;
      cursor: not-allowed;

      &:hover {
        background: #aaa;
        color: $color-text-button;
      }
    }
  }

  &.disabled,
  &:disabled {
    background: #aaa;
    color: $color-text-button;
    cursor: not-allowed;

    &:hover {
      background: #aaa;
      color: $color-text-button;
    }
  }

  &.with-icon {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    .button-img {
      width: $button-img-size * 1;
      height: $button-img-size * 1;
      margin: $main-spacing;
    }
  }

  &.only-icon {
    padding: $main-spacing;

    .button-img {
      width: $button-img-size;
      height: $button-img-size;
      margin: 0;
    }
  }
}

@include gt-sm {
  .button {
    width: auto;
    padding: $main-spacing $main-spacing * 2;
    margin: math.div($main-spacing, 2) 0;
  }
}
