@import '../../../config/variables';
@import '../../../config/mixins.scss';

.logo-container {
  display: none;
  order: 0;
  justify-content: center;
  padding: $main-spacing * 1;
  padding-left: 0;

  .hidden {
    display: none;
  }

  &:hover {
    cursor: pointer;
  }

  img {
    display: block;
    height: 100px;
    width: auto;
    object-fit: contain;
  }

  &.small {
    img {
      height: $main-logo-size / 3.5;
    }
  }

  &.only-bottom-margin {
    padding: 0;
    margin-bottom: $main-spacing * 2;
  }
}

@include gt-sm {
  .logo-container {
    display: flex;
    min-width: 215px;

    img {
      height: 75px;

    }
  }
}

@include gt-lg {
  .logo-container {
    display: flex;
    min-width: 270px;

    img {

      height: 80px;
    }
  }
}
