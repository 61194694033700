@use "sass:math";
@import '../../../../../../config/variables.scss';
@import '../../../../../../config/mixins.scss';

.single-wrapper {
  padding: math.div($main-spacing, 4);
  width: 100%;

  .storage {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    border-radius: 8px;
    width: 100%;
    min-height: $box-size;
    cursor: pointer;
    padding: $main-spacing * 1.5;

    background-image: url(/assets/icons/storage-background.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: right;

    box-shadow: 0 15px 20px -15px #4d4d4d66;


    &.warning {
      border-color: $color-danger;

      .storage-warning-overlay {
        background-color: rgba(lighten($color-danger, 10%), 0.75);
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

    .details {
      position: relative;
      font-weight: 500;
      display: flex;
      width: 100%;
      justify-content: space-between;
    }

    .main-info {
      font-size: 1.2rem;
      display: flex;
      width: 100%;
      justify-content: center;
      margin: $main-spacing 0;

      strong {
        text-align: center;
      }
    }

    .sec-info {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      i {
        font-size: 1rem;
      }
    }

    .sec-info,
    .main-info {
      position: relative;
      text-align: start;
    }
  }
}

@include gt-xs {
  .single-wrapper {
    width: 50%;
  }
}

@include gt-sm {
  .single-wrapper {
    width: 33.33%;

    .storage {
      min-height: $big-box-size;
      justify-content: space-between;
    }
  }
}

@include gt-md {
  .single-wrapper {
    .storage {
      min-height: $big-box-size;
      align-items: center;

      .main-info {
        font-size: 1.4rem;
        font-weight: 500;
      }

      .sec-info,
      .details {
        font-size: 0.9rem;
      }
    }
  }
}