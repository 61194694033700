@use "sass:math";
@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.welcome {
  font-size: 1.5rem;
  color: $color-text;
  font-weight: 700;
}

.welcome-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  padding: 0 $main-spacing;
}

@include gt-sm {
  .welcome {
    font-size: 1.8rem;
  }

  .welcome-wrapper {
    display: flex;
    flex-direction: column;
  }
}

@include gt-lg {
  main {
    .welcome-wrapper {
      margin: math.div($main-spacing, 2) 0 $main-spacing * 2;
      flex-direction: row;
      flex-wrap: wrap;
    }
  }
}
