@use "sass:math";
@import "../../../config/variables";
@import "../../../config/mixins.scss";

.table {
  width: 100%;
  margin: $main-spacing 0;
  font-size: 0.9rem;
  display: block;
  overflow-x: scroll;

  thead {
    font-weight: 400;
    color: $color-text;
    margin-bottom: $main-spacing * 2;
    height: 60px;
    font-size: 0.85rem;

    th {
      padding: $main-spacing;
    }

    tr {
      background: transparent;
    }
  }

  td,
  th {
    padding: math.div($main-spacing, 4) $main-spacing;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    width: 50%;
  }

  img {
    max-width: 100px;
    max-height: 100px;
  }

  .checkbox {
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .button {
    display: inline-block;
    margin-left: 5px;
    line-height: normal;
    vertical-align: middle;
    width: auto;
    height: auto;
  }

  .sort {
    font-size: 0.75rem;
    cursor: pointer;

    &.active {
      color: $color-primary;
    }
  }

  .alert {
    margin: 0;
  }
}

.list {
  .list-element {
    font-size: 0.9rem;
    margin-bottom: math.div($main-spacing, 2);
    border-bottom: 1px solid $color-primary;
    cursor: pointer;
    &:nth-child(odd) {
      background: lighten($color-background, 10%);
    }

    &.toggled {
      .list-row {
        max-height: 250px;
        opacity: 1;
        padding: math.div($main-spacing, 2);
      }
    }
  }

  .list-row {
    border-bottom: 1px solid lighten($color-background, 15%);
    justify-content: space-between;
    display: flex;
    max-height: 0;
    opacity: 0;
    transition: 0.3s all;
    padding: 0 math.div($main-spacing, 2);

    &:last-child {
      border-bottom: none;
    }

    &.mobile {
      padding: math.div($main-spacing, 2);
      max-height: 250px;
      opacity: 1;
    }
  }

  .list-value {
    text-align: right;

    img {
      max-width: 200px;
      max-height: 200px;
    }
  }

  .list-buttons {
    flex: 1;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .button {
      margin: math.div($main-spacing, 4);
      flex: 1 1 auto;
      width: auto;
    }
  }
}

@include gt-md {
  .table {
    th,
    td {
      display: table-cell;
    }
  }
}
