@import '../../../config/variables.scss';
@import '../../../config/mixins.scss';

.page-header-wrapper {
  display: flex;
  align-items: center;
  flex-direction: row;
}

.navigation-wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow: hidden;
  bottom: 0;
  position: fixed;
  background-color: $color-background;
  z-index: 500;
  margin: 0 auto;
  justify-content: flex-end;
  align-items: center;
  // @include safe-padding-bottom(0px);
  // @include safe-height-with-bottom-inset($main-spacing * 6);
  height: $main-spacing * 6;
}

@include gt-lg {
  .page-header-wrapper {
    justify-content: space-between;
    width: 80vw;
    margin: 0 auto $main-spacing;

    .navigation-wrapper {
      justify-content: flex-end;
      margin: 10px 0 0;
    }
  }
}

@include gt-sm {
  .navigation-wrapper {
    position: relative;
  }
}

@include gt-sm {
  .page-header-wrapper {
    margin: 0 auto;
    width: 90vw;
  }
}

@include gt-md {
  .page-header-wrapper {
    margin: 0 auto;
    width: 80vw;
  }
}

@include gt-lg {
  .page-header-wrapper {
    display: flex;
    width: 80vw;
    flex-direction: row;
  }
}

@include gt-xl {
  .page-header-wrapper {
    display: flex;
    width: 65vw;
    flex-direction: row;
  }
}
