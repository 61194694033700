@import '../../../../../config/variables.scss';
@import '../../../../../config/mixins.scss';

.history-wrapper {
  margin: $main-spacing 0;
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
}

.history-list {
  display: flex;
  flex-direction: column;
  width: 100%;
  color: $color-inactive;
  font-weight: 600;
  font-size: 0.8rem;
}

.header-cell {
  flex: 1;
  padding-right: $table-item-spacing/4;
  font-size: 0.8rem;

  word-break: normal;

  &.period {
    flex: 0 0 25%;
  }
  &.number {
    flex: 0 0 20%;
  }
  &.size {
    flex: 0 0 20%;
  }
  &.stock {
    flex: 0 0 35%;
  }
}

.payment-issue {
  color: $color-tertiary;
}

.ended {
  color: $color-disabled;
}

.history-line {
  display: block;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  padding: 7.5px 0;
  border-bottom: 1px solid $color-inactive;

  @include gt-sm {
    display: flex;
  }
}

.header {
  font-weight: 600;
  color: $color-disabled;
  display: none;

  @include gt-sm {
    display: flex;
  }
}

.last {
  margin-right: 0;
}
