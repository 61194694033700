@import '../../../config/variables.scss';

.payment-result-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex: 1;

}

.payment-result-content {
    padding: $main-spacing * 4;
    border-radius: $border-radius * 2;
    background: rgba(#fff, 0.85);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
margin: $main-spacing 0;
}

.status-text {
    font-size: 1.3rem;
    font-weight: 600;
}

.content-text {
    font-size: 1.1rem;
    text-align: center;
    margin: $main-spacing*3;
  }
  
  
  .payment-button-container {
    text-align: center;
    justify-content: center;
  }