@import '../../../config/variables';
@import '../../../config/mixins';

.cookies-information {
  color: #fff;
  width: 100%;
  height: 260px;
  position: fixed;
  bottom: 0px;
  left: 0;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  z-index: 999;

  @include gt-xs {
    height: 200px;
  }

  @include gt-sm {
    height: 150px;
    bottom: 0;
  }

  @include gt-xl {
    height: 120px;
  }

  p {
    max-width: 95vw;
    margin: 0;
    text-align: center;

    @include gt-md {
      margin: 0 auto;
      max-width: 75vw;
    }

    a {
      color: #fff;
      font-weight: bold;
    }
  }

  .exit {
    position: absolute;
    top: -10px;
    right: 5px;
    font-size: 2rem;

    &:hover {
      cursor: pointer;
    }
  }
}

.hide-cookies-bar {
  display: none;
}
