@import '../../../../config/variables';
@import '../../../../config/mixins';


.open-gate-wrapper {
  display: flex;
  width: 100%;
  // align-items: center;
  // justify-content: center;
  flex-direction: column;
}

.animation-wrapper {
  display: flex;
  // align-items: center;
  // justify-content: center;
  flex-direction: column;
  margin-bottom: $main-spacing*2;

  .no-gps-cords {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  span {
    font-size: 1.5rem;
    text-align: center;
  }
}

.gate-animation {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .slide-to-open {
    margin: $main-spacing*2;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
