@use "sass:math";
@import "../../../../../../config/variables.scss";
@import "../../../../../../config/mixins.scss";

.single-option-wrapper {
  display: flex;
  flex-wrap: wrap;
  flex: 0 0 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: math.div($main-spacing, 2);
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 15px 20px -15px #4d4d4d66;

  .radio-img {
    position: relative;
    display: block;
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    // box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.1);
    border: 2px solid darken($color-background, 5%);
    box-shadow: 0 15px 20px -15px lighten(rgba(#000, 0.4), 30%);

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  input[type="radio"] {
    display: none;
  }

  .svg-wrapper {
    height: 200px;

   
  }


  .svg-wrapper {
    padding: $main-spacing * 2;
    padding-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .single-option {
    cursor: pointer;
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .img-description {
    width: 100%;
    text-align: center;
    font-size: 1.1rem;
    font-weight: 700;
  }
}

.img-value {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-20%) rotate(10deg);
  font-size: 2rem;
  color: darken($color-primary, 15%);
  font-weight: 600;
  padding: 0 10px;
}

.img-value-disabled {
  color: $color-danger;
}

.single-option-disable {
  cursor: not-allowed !important;

  .radio-img {
    border: 2px solid darken($color-disabled, 1%);
  }
}

@include gt-sm {
  .single-option-wrapper {
    flex: 0 0 49%;
  }
}

@include gt-sm {
  .single-option-wrapper {
    margin-top: $main-spacing * 1;
    margin-bottom: $main-spacing * 1;

    .radio-img {
      position: relative;
      width: 100%;
    }
  }

  .img-value {
    font-size: 3rem;
  }

  .single-option-disable {
    &::before {
      width: 100%;
      height: $single-option-height-big;
    }
  }
}
