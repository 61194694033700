@use "sass:math";
@import "../../../config/variables.scss";
@import "../../../config/mixins.scss";

.tabs {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: $main-spacing 0;
  overflow: hidden;
  border-radius: 3px;

  @include gt-xs {
    margin-bottom: $main-spacing * 4;
  }

  .warning {
    position: relative;

    &::before {
      position: absolute;
      content: "";
      background-repeat: no-repeat;
      background-size: 30px;
      right: 10px;
      width: 30px;
      height: 30px;
      background-color: lighten(red, 20%);
      border-radius: 50%;
    }
  }

  .tab {
    flex: 0 1 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: $main-spacing math.div($main-spacing, 2);
    font-family: $font-primary;
    font-weight: 300;
    font-size: 0.85rem;

    // &:hover {
    //   background-color: lighten($color-background, 30%);
    //   background: $color-primary-light;
    // }
  }
}

@include gt-xs {
  .tabs {
    flex-direction: row;

    .tabs {
      flex: 0 1 100%;
    }
  }
}

@include gt-sm {
  .tabs {
    flex-wrap: nowrap;
  }
}
